// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
// const currentUser = JSON.parse(localStorage.getItem('currentUser'));
export const environment = {
  production: true,
  // dont forget seats data
  // apiEndPoint: 'http://prod.zabatnee.com/api',
  // apiEndPoint: 'http://prod.zabatnee.com/api',
  // apiEndPoint: 'https://prod.flutter.zabatnee.com/api',
  apiEndPoint: 'https://dev.flutter.zabatnee.com/api',

  // apiEndPoint: 'http://staging.zabatnee.com/api',

  // apiEndPoint: 'https://dev.flutter.zabatnee.com/api',

  clientId: '3xc443jG39RJkE2JBx1YuoWcLHwz98UkBZoYHXFm',
  clientSecret:
    'nSkSvunanEVAxjf1yL7R2aKUm72kwl1yJta7Q9QDt0zgb2M2tDj8InW0587UU8yocUWh5Dr2CE5mh41ogVoNnmbtArs7RDsScU2hsAZVHryyEoigkVMxSStVXXkrCiQ3',
  AWS: {},
  httpHeader: { 'content-type': 'application/json' },
  googleMapsApiEndPoint: 'https://maps.googleapis.com/maps/api/geocode/json',
  googleMapsApiKey: 'AIzaSyD1uXumOcpOZ5iLZXJe2lwEzn-NT0cw2tw'
};



